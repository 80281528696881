<template>
  <div>
    <div
      v-if="imageUrl && isVideo && !editImage"
      class="fill-height"
      style="position: relative"
    >
      <video
        style="max-width: 100%"
        class="mx-2"
        playsinline
        autoplay
        :muted="videoMuted"
        loop
      >
        <source :src="imageUrl" type="video/mp4" />
      </video>
      <v-btn
        v-if="allowEdit"
        fab
        absolute
        top
        rounded
        right
        class="mt-2"
        style="z-index: 1; position: absolute; right: 0; top: 0"
        @click="editImage = true"
      >
        <v-icon small>mdi-pencil</v-icon>
      </v-btn>
      <!-- add an unmuting button if the video is muted -->
      <v-btn
        text
        absolute
        bottom
        right
        rounded
        class="mb-10 white--text"
        dark
        style="z-index: 1; position: absolute; right: 0; bottom: 0"
        @click="videoMuted = !videoMuted"
      >
        <v-icon v-if="!videoMuted">mdi-volume-high</v-icon>
        <v-icon v-else>mdi-volume-off</v-icon>
      </v-btn>
    </div>
    <v-row
      v-else-if="allowEdit || editImage"
      v-show="!imageUrl || editImage"
      justify="start"
      style="border: 2px solid var(--v-light-background)"
      class="mb-3 mx-1"
    >
      <v-col cols="12" class="image-dropzone">
        <v-container fill-height justify-center>
          <div style="text-align: center">
            <h1 class="font-weight-thin text-uppercase">Drop here</h1>
            <br />
            <h3>or</h3>
            <vue-upload-component
              :ref="`upload-${name}`"
              :key="`upload-${name}`"
              v-model="image"
              :input-id="`upload-${name}`"
              :drop="true"
              :drop-directory="true"
              :accept="allowVideo ? 'image/*, video/*' : 'image/*'"
              @input-file="handleImage()"
            >
              <v-btn class="mt-4 pa-5" color="primary" outlined>
                <v-icon class="mr-2">mdi-upload</v-icon>
                Choose Image {{ allowVideo ? "or Video" : "" }}
              </v-btn>
            </vue-upload-component>
            <p class="mt-4">
              <span class="mb-4">Current {{ isVideo ? "video" : "image" }}:</span>
              <video
                v-if="imageUrl && isVideo"
                style="max-width: 100%; max-height: 200px"
                class="mb-n2"
                playsinline
                autoplay
                muted
                loop
              >
                <source :src="imageUrl" type="video/mp4" />
              </video>
              <v-img v-else-if="imageUrl" :src="imageUrl" height="60px" contain />
              <span v-if="!imageUrl">
                {{ image.length ? image[0].name : imageUrl ? imageUrl : "N/A" }}
              </span>
            </p>
            <v-btn v-if="imageUrl && editImage" text @click="editImage = false">
              Cancel
            </v-btn>
          </div>
        </v-container>
      </v-col>
    </v-row>
    <v-row
      v-if="imageUrl && !isVideo && !editImage"
      justify="center"
      class="my-0 mb-3 mx-1"
    >
      <v-col
        cols="auto"
        class="pa-0"
        style="border: 2px solid var(--v-light-background)"
      >
        <v-container justify-center class="pa-0">
          <v-img
            :src="imageUrl"
            contain
            :max-height="small ? 200 : 400"
            :max-width="small ? 200 : 600"
            min-height="200"
            min-width="200"
          >
            <div v-if="allowEdit" v-show="imageUrl">
              <v-btn
                fab
                absolute
                top
                rounded
                right
                class="mt-8"
                @click="editImage = true"
              >
                <v-icon small>mdi-pencil</v-icon>
              </v-btn>
            </div>
          </v-img>
        </v-container>
      </v-col>
    </v-row>
    <v-row v-else-if="!allowEdit">
      <v-col>
        <p class="text-center">No image available.</p>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { uploadBytes, ref, getDownloadURL, getMetadata } from "@firebase/storage"
import VueUploadComponent from "vue-upload-component"
import { storage } from "../../firebaseConfig"
import { mapState, mapMutations } from "vuex"

export default {
  name: "custom-image-upload",
  components: { VueUploadComponent },
  props: {
    name: {
      type: String,
      required: true,
    },
    imageUrl: {
      type: [String, undefined],
      required: true,
    },
    allowEdit: {
      type: Boolean,
      required: false,
      default: true,
    },
    allowVideo: {
      type: Boolean,
      required: false,
      default: false,
    },
    location: {
      type: String,
      required: false,
      default: "",
    },
    small: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      preppedImage: "",
      image: [],
      editImage: false,
      videoMuted: true,
      metadata: null,
    }
  },
  computed: {
    ...mapState(["firebaseRefs"]),
    isVideo() {
      return this.metadata?.contentType?.includes("video")
    },
    imageRefFromUrl() {
      if (!this.imageUrl) return null
      const imagePath = decodeURIComponent(
        this.imageUrl
          ?.split(storage?._location?.bucket + "/o/")?.[1]
          ?.split("?")?.[0]
      )
      if (imagePath && imagePath !== "undefined") {
        return ref(storage, imagePath)
      } else return null
    },
  },
  watch: {
    imageUrl() {
      this.getImageMetadata()
    },
  },
  mounted() {
    this.getImageMetadata()
  },
  methods: {
    ...mapMutations(["setNotification"]),
    getImageMetadata() {
      if (this.imageRefFromUrl) {
        getMetadata(this.imageRefFromUrl)
          .then(metadata => {
            this.metadata = metadata
          })
          .catch(error => {
            console.error("Error retrieving image metadata:", error)
          })
      }
    },
    async handleImage() {
      let imageUploaded = false
      if (this.image.length) {
        imageUploaded = await this.uploadImage()
      }
      this.image = []
      if (imageUploaded) {
        this.editImage = false
      }
      this.preppedImage = ""
    },
    async uploadImage() {
      // create a name for this file using the current date, time and also a 6-digit random string
      // this is to ensure that the file name is unique
      const fileName =
        new Date().toISOString().replace(/:/g, "-") +
        "-" +
        Math.random().toString(36).substring(2, 8)

      const fileType = this.image[0].type.includes("image")
        ? "image"
        : this.image[0].type.includes("video")
        ? "video"
        : null
      switch (fileType) {
        case "image":
          break
        case "video":
          if (!this.allowVideo) {
            this.setNotification({
              message: "The file you are trying to upload is not an image.",
              icon: "mdi-alert",
            })
            return false
          }
          break
        default:
          this.setNotification({
            message: this.allowVideo
              ? "The file you are trying to upload is not an image or a video."
              : "The file you are trying to upload is not an image.",
            icon: "mdi-alert",
          })
          return false
      }
      const imagePath = this.location
        ? `Organizations-v1/${this.firebaseRefs.organization.id}/${this.location}`
        : `restaurantManagerUploads/${this.name}/${fileName}`
      const imageRef = ref(storage, imagePath)
      await uploadBytes(imageRef, this.image[0].file)
      const imageURL = await getDownloadURL(imageRef)
      this.preppedImage = imageURL
      this.$emit("imageProcessed", this.preppedImage)
      return true
    },
  },
}
</script>

<style lang="scss" scoped>
.image-dropzone {
  background: var(--v-light-background-base);
  padding: 20px 10px 10px;
  border-style: dashed;
  border-width: medium;
  border-color: var(--v-light-background-darken1);
  border-radius: 10px;
}
</style>
